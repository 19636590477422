<template>
  <v-card
    min-height="100vh"
    class="xw-full"
    :class="[tabletDown ? '' : 'xp-[1em]']"
    color="transparent"
    flat
  >
    <v-card-title
      :class="[
        mobile ? 'xgrid-cols-1' : tablet ? 'xgrid-cols-2' : 'xgrid-cols-5',
      ]"
      class="xgap-[1em] xgrid xjustify-center xitems-stretch xbg-[#fff] xrounded-[10px]"
    >
      <div
        :class="[mobile || tablet ? 'xgrid-cols-1' : 'xgrid-cols-2']"
        class="md:xcol-span-2 xcol-span-1 xflex md:xflex-row xflex-col xp-[1em] xgap-[1em] xjustify-between xitems-stretch xbg-[#7A38A3] xrounded-md"
      >
        <div
          class="xflex xflex-col md:xw-6/12 xw-full xjustify-center xitems-start"
        >
          <h3
            class="xmb-1 xfont-[600] xtext-[18px] xleading-[24px] xtext-[#ffffff]"
          >
            Team Subscription
          </h3>
          <div class="xflex xflex-col xgap-y-[5px] xw-full" v-if="workspace">
            <h5 class="xtext-[12px] xleading-[14.5px] xfont-[500] xtext-[#fff]">
              {{ workspace.name }}
            </h5>
            <h5 class="xtext-[12px] xleading-[14.5px] xfont-[500] xtext-[#fff]">
              {{ workspace.created_at | format("MM-DD-YYYY HH:mm A") }}
            </h5>
            <h5 class="xtext-[12px] xleading-[14.5px] xfont-[500] xtext-[#fff]">
              {{ workspace.domain }}.slack.com
            </h5>
          </div>
        </div>
        <div
          v-if="subscription"
          class="xflex xflex-col md:xw-6/12 xw-full xjustify-center xitems-start"
        >
          <span
            v-if="subscription.is_exempted"
            class="xfont-[700] xtext-[35px] xleading-[42px] xtext-[#fff]"
          >
            Exempted
          </span>
          <span
            v-else
            class="xfont-[700] xtext-[1.6em] xleading-[42px] xtext-[#fff]"
          >
            ${{ subscriptionCount * 2 }} /month
          </span>
          <template v-if="!subscription.is_exempted">
            <v-btn
              v-if="
                (!subscription.is_subscribed && !subscription.is_exempted) ||
                (subscription.is_cancelled && subscription.cancelled_and_ended)
              "
              small
              outlined
              dark
              :block="smDown"
              class="text-none px-5 xmt-[5px]"
              @click="$router.push({ name: 'app-checkout' })"
              color="light"
            >
              Upgrade Plan
            </v-btn>
            <v-btn
              v-if="subscription.is_subscribed && !subscription.is_cancelled"
              :disabled="processing"
              :loading="processing"
              :block="smDown"
              outlined
              small
              @click="handleCancelation"
              class="text-none px-5 xmt-[5px]"
              color="light"
            >
              Cancel Subscription
            </v-btn>
            <v-btn
              small
              v-else-if="
                subscription.is_cancelled &&
                subscription.cancelled_and_on_grace_period
              "
              outlined
              :disabled="processing"
              :loading="processing"
              :block="smDown"
              @click="handleResume"
              class="text-none xmt-[5px] px-5"
              color="light"
            >
              Resume Subscription
            </v-btn>
          </template>
        </div>
      </div>

      <div
        class="xcol-span-1 xflex xflex-col xp-[1em] xjustify-start xitems-stretch xbg-[#2386FA] xrounded-md xgap-y-[0.5em]"
      >
        <h3
          class="xmb-1 xfont-[600] xtext-[18px] xleading-[24px] xtext-[#ffffff]"
        >
          Active Members
        </h3>
        <h1 class="xtext-[1.6em] xleading-[36.5px] xfont-[700] xtext-[#fff]">
          {{ statistics.active_users_count || 0 }}
        </h1>
        <small class="xtext-[12px] xleading-[14px] xtext-[#ffffff]">
          Since
          {{ workspace ? workspace.created_at : null | format("lll") }}
        </small>
      </div>

      <div
        class="xcol-span-1 xflex xflex-col xp-[1em] xjustify-start xitems-stretch xbg-[#1FBE1C] xrounded-md xgap-y-[0.5em]"
      >
        <h3
          class="xmb-1 xfont-[600] xtext-[1rem] xleading-[24px] xtext-[#ffffff]"
        >
          {{
            subscription && subscription.status == "EXEMPTED"
              ? "Allowed"
              : "Paying Members"
          }}
        </h3>
        <h1 class="xtext-[1.6em] xleading-[36.5px] xfont-[700] xtext-[#fff]">
          {{ statistics.payings_count || 0 }}
        </h1>
        <small class="xtext-[12px] xleading-[14px] xtext-[#ffffff]">
          Since
          {{ workspace ? workspace.created_at : null | format("lll") }}
        </small>
      </div>

      <div
        class="xcol-span-1 xflex xflex-col xp-[1em] xjustify-start xitems-stretch xbg-[#949494] xrounded-md xgap-y-[0.5em]"
      >
        <h3
          class="xmb-1 xfont-[600] xtext-[1rem] xleading-[24px] xtext-[#ffffff]"
        >
          Inactive Members
        </h3>
        <h1 class="xtext-[1.6em] xleading-[36.5px] xfont-[700] xtext-[#fff]">
          {{ statistics ? statistics.trashed_users_count : 0 }}
        </h1>
        <small class="xtext-[12px] xleading-[14px] xtext-[#ffffff]">
          Since
          {{ workspace ? workspace.created_at : null | format("lll") }}
        </small>
      </div>
    </v-card-title>
    <v-card-text>
      <v-card
        class="xw-full xmt-3 xmb-[1em] !xborder-[#000]"
        rounded="lg"
        flat
        outlined
        v-if="
          subscription &&
          !subscription.is_subscribed &&
          !subscription.is_exempted
        "
      >
        <v-card-text
          class="xflex xjustify-between xgap-2 xitems-center md:xflex-row xflex-col"
        >
          <span
            v-if="subscription.is_free_trial"
            class="xtext-gray-100 xfont-semibold"
          >
          Your team is currently on a FREE trial. Click "Upgrade Subscription" to keep your team seamlessly integrated with {{ appName }}.<br />
            <span v-if="!subscription.is_free_trial_lapsed">
              Your free trial will end on
              {{ subscription.free_trial_ends_at | format }} UTC
            </span>
            <span v-else>
              Your free trial ended on
              {{ subscription.free_trial_ends_at | format }} UTC
            </span>
          </span>
          <span class="xtext-white" v-if="subscription.is_cancelled">
            Your team has currently paused the subscription with
            {{ appName }}.<br />
            Click subscribe to continuously integrate your team with
            {{ appName }}
          </span>
          <v-btn
            :block="smDown"
            class="text-none px-5"
            @click="$router.push({ name: 'app-checkout' })"
            color="primary"
          >
            Upgrade Subscription
          </v-btn>
        </v-card-text>
      </v-card>
      <app-tabs :tabs="tabs" optional>
        <router-view :key="$route.fullPath"></router-view>
      </app-tabs>
    </v-card-text>

    <!-- <pre>{{ statistics }}</pre> -->
    <!-- <pre>{{ workspace }}</pre> -->
    <!-- <pre>{{ subscription }}</pre> -->
  </v-card>
</template>

<script>
import _ from "lodash";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters("workspace", ["subscription", "workspace", "statistics"]),
    tabs() {
      if (!this.$can.and("update_subscription")) return [];
      return [
        { name: "Manage Paying Users", to: { name: "app-subscription" } },
        { name: "Billing History", to: { name: "app-subscription-history" } },
      ];
    },
    subscriptionCount() {
      return this.subscription ? this.subscription.payings_count : 0;
    },
    subscriptionName() {
      // Basic Monthly | Free Trial | Exempted
      if (!this.subscription) return "Free Trial";
      else if (this.subscription.is_exempted) return "Exempted";
      else return "Basic Monthly";
    },
    subcriptionStatus() {
      // Active | Inactive | Free Trial | Cancelled | Free Trial Expired
      if (!this.subscription) return "On Free Trial";
      if (
        this.subscription.status === "BASIC MONTHLY" ||
        this.subscription.is_exempted
      )
        return "Active";
      else if (this.subscription.cancelled_and_on_grace_period)
        return (
          "Canceled, Active Until " +
          moment(this.grace_period_ends_at).format("LL")
        );
      else if (this.subscription.cancelled_and_ended)
        return "Canceled, Inactive";
      else if (this.subscription.is_free_trial_lapsed)
        return "Free Trial Ended";
      else return "On Free Trial";
    },
  },
  data() {
    return {
      tab: "users",
      processing: false,
    };
  },
  created() {
    this.fetchWorkspaceSubscription(this.user.team_id);
    this.fetchWorkspaceStatistics(this.user.team_id);
  },
  methods: {
    ...mapActions("workspace", [
      "fetchWorkspaceSubscription",
      "fetchWorkspaceStatistics",
    ]),
    handleCancelation() {
      this.appConfirmation(
        "Are you sure you want to cancel your subscription to Mesasix Time Tracker?",
        () => {
          this.processing = true;
          this.$axios
            .post(`api/subscription/cancel`, {})
            .then(({ data }) => {
              this.appToast(data.message, "success");
              this.fetchWorkspaceSubscription(this.user.team_id);
            })
            .finally(() => (this.processing = false));
        }
      );
    },
    handleResume() {
      this.appConfirmation(
        "Are you sure you want to resume your subscription to Mesasix Time Tracker?",
        () => {
          this.processing = true;
          this.$axios
            .post(`api/subscription/resume`, {})
            .then(({ data }) => {
              this.appToast(data.message, "success");
              this.fetchWorkspaceSubscription(this.user.team_id);
            })
            .finally(() => (this.processing = false));
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
