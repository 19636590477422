<template>
  <app-modal
    :valid="!!name && !!region_id"
    :title="title"
    v-model="open"
    width="500"
    :confirm-label="isEdit ? 'Update' : 'Save'"
    @confirmed="submit"
  >
    <v-card flat color="transparent">
      <v-card-text>
        <label class="xfont-bold xleading-[1em]">Region *</label>
        <v-select
          :items="regions"
          item-text="name"
          outlined
          dense
          append-icon="mdi-chevron-down"
          placeholder="Select region"
          item-value="id"
          :rules="[requiredRule()]"
          hide-details="auto"
          v-model="region_id"
          class="mb-2"
        ></v-select>
        <label class="xfont-bold xleading-[1em]">Name *</label>
        <app-input
          type="text"
          placeholder="Enter data center name"
          :rules="[requiredRule()]"
          v-model="name"
          class="mb-2"
        ></app-input>
      </v-card-text>
    </v-card>
  </app-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    value: { type: Boolean, default: false },
    item: { type: Object, default: undefined },
  },
  data() {
    return { open: false, name: null, region_id: null };
  },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters("workspace", ["regions"]),
    payload() {
      return {
        name: this.name,
        region_id: this.region_id,
      };
    },
    isEdit() {
      return !!this.item;
    },
    title() {
      return this.isEdit ? "Update Data Center" : "New Data Center";
    },
  },
  watch: {
    value: {
      handler: function (val) {
        this.open = val;
        if (val && !this.isEdit) this.name = null;
      },
      immediate: true,
    },
    open: {
      handler: function (val) {
        this.$emit("input", val);
      },
      immediate: true,
    },
    item: {
      handler: function (val) {
        this.region_id = val ? val.region_id : null;
        this.name = val ? val.name : null;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions("workspace", ["fetchWorkspaceStatistics"]),
    submit() {
      if (this.isEdit) this.handleUpdate();
      else this.handleSave();
    },
    handleSave() {
      this.$axios.post(`api/data-centers`, this.payload).then(({ data }) => {
        this.$emit("saved", data);
        this.open = false;
        this.appToast("A new data center has been created.", "success");
        this.fetchWorkspaceStatistics(this.user.team_id);
      });
    },
    handleUpdate() {
      this.$axios
        .put(`api/data-centers/${this.item.id}`, this.payload)
        .then(({ data }) => {
          this.$emit("updated", data);
          this.open = false;
          this.appToast("The data center has been updated.", "success");
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
